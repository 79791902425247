<template>
    <div class="loader" :class="{ 'loader--show': show, 'loader--loading': loading, 'loader--done': done }"></div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'ui-loader',
    computed: mapState({
        state: 'loading'
    }),
    data() {
        return {
            threshold: 100,
            timeout: null,
            show: false,
            loading: false,
            done: false
        }
    },
    watch: {
        state(value, oldValue) {
            if (!oldValue && value) {
                this.timeout = setTimeout(() => {
                    this.show = true
                }, this.threshold)
                setTimeout(() => this.loading = true, this.threshold + 100)
            } else if (oldValue && !value) {
                clearTimeout(this.timeout)
                this.done = true
                setTimeout(() => this.show = false, 300)
                setTimeout(() => {
                    this.done = false
                    this.loading = false
                }, 400);
            } else {
                this.show = false
                this.loading = false
                this.done = false
            }
        }
    }
}
</script>

