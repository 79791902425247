<template>
    <div class="login">
    <div class="content content--full-height content--flex">
            <form class="form" @submit.prevent="performLogin">
                <div class="card card--primary" style="width: 600px; max-width: 80vw">
                    <div class="card__header">Login</div>
                    <div class="card__body">
                        <fieldset>
                            <frm-input
                                label="E-Mail Adresse"
                                id="email"
                                type="text"
                                :value.sync="data.email"
                                :error="errors.email"
                            />
                            <frm-input
                                label="Passwort"
                                id="password"
                                type="password"
                                :value.sync="data.password"
                                :error="errors.password"
                            />
                        </fieldset>
                    </div>
                    <div class="card__footer">
                        <button type="submit" class="button button--primary button--flat">Anmelden</button>
                        <a href="#" class="button button--default button--flat">Passwort vergessen?</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import FrmInput from '@/components/form/FrmInput'

export default {
    name: 'login',
    components: {
        FrmInput
    },
    data() {
        return {
            data: {},
            errors: {}
        }
    },
    methods: {
        setErrors(errors) {
            const tmp = {}
            for (const k in errors) {
                const v = errors[k]
                tmp[k] = v[0]
            }
            this.errors = tmp
        },
        clearErrors() {
            const tmp = {}
            for (const k in this.errors) {
                tmp[k] = ''
            }
            this.errors = tmp
        },
        performLogin() {
            this.clearErrors()

            const validator = this.$validate({
                email: 'required|email',
                password: 'required'
            })

            if (validator.fails()) {
                this.setErrors(validator.errors.all())
                return
            }

            this.$store.dispatch('load')
            .then(() => this.$store.dispatch('auth/login', this.data))
            .then(() => this.$router.push({ name: 'home' }))
            .catch(err => {
                const res = err.response
                if (this.$store.state.loading) {
                    this.$store.commit('loading')
                }
                if (!res || res.status === 500) {
                    this.errors.email = 'Ein technischer Fehler ist aufgetreten'
                    return
                }
                if (res.status === 400 || res.status === 422) {
                    this.setErrors(res.data)
                }
            })
        }
    }
}
</script>
