<template>
    <div class="menu" :class="{ 'menu--open': open }">
        <button type="button" class="button button--flat button--icon" :class="colorClass" ref="toggle" @click.stop="open = !open">
            <i class="material-icons">{{ icon }}</i>
        </button>
        <div class="menu__items" ref="items">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ui-menu',
    props: {
        color: {
            type: String,
            default: ''
        },
        direction: {
            type: String,
            validator: (val) => ['left', 'right'].includes(val),
            default: 'right'
        },
        icon: {
            type: String,
            default: 'more_vert'
        }
    },
    data() {
        return {
            open: false
        }
    },
    computed: {
        colorClass() {
            return this.color ? `button--${this.color}` : ''
        }
    },
    methods: {
        calcPos(pos) {
            const button = this.$refs.toggle
            const buttonRect = button.getBoundingClientRect()
            const pageXOffset = window.pageXOffset
            const pageYOffset = window.pageYOffset
            const menuItems = this.$refs.items
            const openingMethod = this.pos ? 'context' : 'button'

            pos = pos || {
                x: buttonRect.right,
                y: buttonRect.bottom + 4
            }

            // Prevent menu from clipping out horizontally
            if (this.direction === 'left' || (menuItems.clientWidth + pos.x) > window.innerWidth) {
                pos.x -= menuItems.clientWidth
            }

            // Prevent menu from clipping out vertically
            if ((menuItems.clientHeight + pos.y) > window.innerHeight) {
                pos.y -= menuItems.clientHeight
                if (openingMethod === 'button') {
                    pos.y -= button.clientHeight + 2 * 4
                }
            }

            return pos
        },
        onScroll() {
            if (this.open) {
                let pos = this.calcPos()
                this.$refs.items.style.transform = `translate(${pos.x}px, ${pos.y}px)`
            }
        },
        closeMenu() {
            this.open = false
        },
        closeAllMenus() {
            if (document.querySelector('.menu--open')) {
                this.$root.$emit('menu-close-all')
            }
        }
    },
    watch: {
        open(val) {
            this.$nextTick(() => {
                if (val) {
                    let pos = this.calcPos()
                    this.$refs.items.style.transform = `translate(${pos.x}px, ${pos.y}px)`
                } else {
                    this.$refs.items.style = ''
                }
            })
        }
    },
    mounted() {
        document.addEventListener('click', this.closeMenu)
        this.$el.querySelectorAll('.menu__item').forEach(elm => {
            elm.addEventListener('click', this.closeMenu)
        })
        window.addEventListener('scroll', this.onScroll)
        this.$root.$on('menu-close-all', () => this.open = false)
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeMenu)
        this.$el.querySelectorAll('.menu__item').forEach(elm => {
            elm.removeEventListener('click', this.closeMenu)
        })
        window.removeEventListener('scroll', this.onScroll)
        this.$root.$off('menu-close-all')
    }
}
</script>

