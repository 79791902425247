<template>
    <div class="drawer drawer--primary" :class="{ 'drawer--open': $store.state.drawer }" id="drawer" @click.stop>
        <div class="drawer__content">
            <div class="drawer__header">
                <div class="drawer__logo">
                    <router-link to="/">
                        <ui-logo />
                    </router-link>
                </div>
                <button type="button" class="button button--flat button--icon" @click="closeDrawer">
                    <i class="material-icons">close</i>
                </button>
            </div>
            <nav class="list">
                <ul>
                    <!-- <li>
                        <router-link to="/">Home</router-link>
                    </li> -->
                    <li>
                        <router-link to="/product">Produktsortiment</router-link>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
import UiLogo from '@/components/UiLogo'

export default {
    name: 'ui-drawer',
    components: {
        UiLogo
    },
    methods: {
        closeDrawer() {
            if (this.$store.state.drawer) {
                this.$store.commit('drawer', false)
            }
        }
    },
    mounted() {
        document.addEventListener('click', this.closeDrawer)
        this.$el.querySelectorAll('a').forEach(elm => {
            elm.addEventListener('click', this.closeDrawer)
        })
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeDrawer)
        this.$el.querySelectorAll('a').forEach(elm => {
            elm.removeEventListener('click', this.closeDrawer)
        })
    }
}
</script>
