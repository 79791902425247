export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('auth') || '',
        user: {},
        company: {}
    },
    getters: {
        loggedIn(state) {
            return !!state.token
        },
        axios_options(state) {
            return {
                headers: {
                    'Authorization': `Bearer ${state.token}`
                }
            }
        }
    },
    mutations: {
        token(state, token) {
            state.token = token
            if (!token.length) {
                localStorage.removeItem('auth')
            } else {
                localStorage.setItem('auth', token)
            }
        },
        user(state, user) {
            state.user = user
        },
        company(state, company) {
            state.company = company
        },
        clear(state) {
            state.token = ''
            state.user = {}
            localStorage.removeItem('auth')
        }
    },
    actions: {
        get({ getters }, { url, options }) {
            url = url || ''
            options = options || {}
            return usersApi.get(url, Object.assign(getters['axios_options'], options))
        },
        post({ getters }, { url, data, options }) {
            url = url || ''
            data = data || {}
            options = options || {}
            return usersApi.post(url, data, Object.assign(getters['axios_options'], options))
        },
        delete({ getters }, { url, options }) {
            url = url || ''
            options = options || {}
            return usersApi.delete(url, Object.assign(getters['axios_options'], options))
        },
        async user({ commit, getters }) {
            try {
                const [ user, company ] = await Promise.all([
                    usersApi.get('/v1/auth/user', getters['axios_options']),
                    usersApi.get('/v1/auth/company', getters['axios_options'])
                ])

                commit('user', user.data)
                commit('company', company.data)

                return Promise.resolve({ user, company })
            } catch (err) {
                commit('clear')
                throw err
            }
        },
        async login({ commit }, data) {
            try {
                const res = await usersApi.post('/v1/auth/login', data)
                commit('token', res.data.token)
            } catch (err) {
                throw err
            }
        },
        async renew({ state, getters }) {
            if (!state.token) {
                throw new Error('Token not found')
            }
            try {
                await usersApi.get('/v1/auth/renew', getters['axios_options'])
            } catch (err) {
                throw err
            }
        },
        async reissue({ commit, getters }) {
            try {
                const res = await usersApi.get('/v1/auth/reissue', getters['axios_options'])
                commit('token', res.data.token)
            } catch (err) {
                throw err
            }
        },
        async logout({ commit, getters }) {
            try {
                const res = await usersApi.get('/v1/auth/logout', getters['axios_options'])
                commit('clear')
            } catch (err) {
                throw err
            }
        }
    }
}