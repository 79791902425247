import Vue from 'vue'
import vjs from 'validatorjs'

vjs.useLang('de')
vjs.setMessages('de', Object.assign(
    vjs.getMessages('de'), {
    email: 'Das ist keine gültige E-Mail Adresse.',
    required: 'Dieses Feld muss ausgefüllt werden.',
    regex: 'Dieses Format ist ungültig.'
}))

const Validator = {
    install(vue) {
        vue.prototype.$validate = function(rules, customMessages) {
            return new vjs(this.data, rules, customMessages)
        }
    }
}

Vue.use(Validator)

export default Validator