import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login'
import store from './store'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import( /* webpackChunkName: "user.chunk" */ './views/Home.vue'),
            redirect: { name: 'product' },
            meta: {
                requiresAuth: true
            }
        },{
            path: '/login',
            name: 'login',
            component: Login
        },{
            path: '/product',
            name: 'product',
            component: () => import( /* webpackChunkName: "user.chunk" */ './views/Product.vue'),
            meta: {
                requiresAuth: true
            }
        },{
            path: '/product/:productId',
            name: 'product.detail',
            component: () => import( /* webpackChunkName: "user.chunk" */ './views/product/Detail.vue'),
            redirect: { name: 'product.detail.description' },
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    path: 'description',
                    name: 'product.detail.description',
                    component: () => import( /* webpackChunkName: "user.chunk" */ './views/product/detail/Description.vue')
                },{
                    path: 'specification',
                    name: 'product.detail.specification',
                    component: () => import( /* webpackChunkName: "user.chunk" */ './views/product/detail/Specification.vue')
                },{
                    path: 'packageunit',
                    name: 'product.detail.packageunit',
                    component: () => import( /* webpackChunkName: "user.chunk" */ './views/product/detail/Packageunit.vue')
                }
            ]
        },{
            path: '/account',
            name: 'account',
            component: () => import( /* webpackChunkName: "user.chunk" */ './views/Account.vue'),
            redirect: { name: 'account.personal' },
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    path: 'personal',
                    name: 'account.personal',
                    component: () => import( /* webpackChunkName: "user.chunk" */ './views/account/Personal.vue'),
                    children: [
                        {
                            path: 'password',
                            name: 'account.personal.password',
                            component: () => import(/* webpackChunkName: "user.chunk" */ './views/account/personal/Password.vue')
                        }
                    ]
                },{
                    path: 'company',
                    name: 'account.company',
                    component: () => import( /* webpackChunkName: "manager.chunk" */ './views/account/Company.vue'),
                    meta: {
                        requiresManager: true
                    }
                },{
                    path: 'users',
                    name: 'account.users',
                    component: () => import( /* webpackChunkName: "manager.chunk" */ './views/account/Users.vue'),
                    meta: {
                        requiresManager: true
                    },
                    children: [
                        {
                            path: 'create',
                            name: 'account.users.create',
                            component: () => import(/* webpackChunkName: "manager.chunk" */ './views/account/users/Create.vue')
                        },{
                            path: ':userId',
                            name: 'account.users.update',
                            component: () => import(/* webpackChunkName: "manager.chunk" */ './views/account/users/Update.vue')
                        },{
                            path: ':userId/delete',
                            name: 'account.users.delete',
                            component: () => import(/* webpackChunkName: "manager.chunk" */ './views/account/users/Delete.vue')
                        }
                    ]
                }
            ]
        },{
            path: '/**',
            redirect: { name: 'home' }
        }
    ]
})

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const requiresManager = to.matched.some(record => record.meta.requiresManager)

    // No authentication needed
    if (!requiresAuth) {
        return next()
    }

    // Not authenticated
    if (!store.state.auth.token) {
        if (store.state.loading) {
            store.commit('loading')
        }
        store.commit('auth/clear')
        return next({ name: 'login' })
    }

    // Is authenticated
    const isFirstLoad = !Object.keys(store.state.auth.user).length
    if (isFirstLoad) { // If user has not been fetched
        try {
            await store.dispatch('auth/user')
        } catch {
            store.commit('auth/clear')
            next({ name: 'login' })
        }
    }

    // Check manager access
    if (requiresManager && !store.state.auth.user.manager) {
        if (isFirstLoad) {
            return next({ name: 'home' })
        }
        return next(false)
    }

    // Renew User token
    try {
        store.commit('loading', true)
        await store.dispatch('auth/renew')
        store.commit('loading')
    } catch {
        store.commit('auth/clear')
        next({ name: 'login' })
    } finally {
        store.commit('loading')
    }

    next()
})

router.afterEach((to, from) => {
    if (store.state.loading) {
        store.commit('loading')
    }
})

export default router