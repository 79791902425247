<template>
    <div class="header header--primary">
        <div class="header__main">
            <button type="button" class="button button--primary button--flat button--icon" @click.stop="$store.commit('drawer', true)">
                <i class="material-icons">menu</i>
            </button>
            <div class="header__logo">
                <router-link to="/">
                    <ui-logo />
                </router-link>
            </div>
        </div>
        <div class="header__options">
            <ui-menu color="primary" icon="person">
                <div class="menu__item menu__item--header" v-if="Object.keys($store.state.auth.user).length">
                    <div><strong>{{ $store.state.auth.company.name }}</strong></div>
                    <div>{{ $store.state.auth.user.salutation === 'ms' ? 'Frau' : 'Herr' }} {{ $store.state.auth.user.first_name }} {{ $store.state.auth.user.last_name }}</div>
                </div>
                <router-link to="/account" class="menu__item">Konto verwalten</router-link>
                <div class="menu__divider"></div>
                <a href="#" class="menu__item" @click.prevent="logout()">Abmelden</a>
            </ui-menu>
        </div>
    </div>
</template>

<script>
import UiLogo from '@/components/UiLogo'
import UiMenu from '@/components/UiMenu'

export default {
    name: 'ui-header',
    components: {
        UiLogo,
        UiMenu
    },
    methods: {
        logout() {
            this.$store.dispatch('load')
            .then(() => this.$store.dispatch('auth/logout'))
            .then(() => this.$router.push({ name: 'login' }))
            .catch(() => this.$router.push({ name: 'login' }))
        }
    }
}
</script>
