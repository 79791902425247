<template>
    <div id="app">
        <ui-loader />
        <ui-header v-if="$store.getters['auth/loggedIn']" />
        <ui-drawer v-if="$store.getters['auth/loggedIn']" />
        <router-view />
        <div class="alerts">
            <transition name="slide-left" mode="in-out" :key="alert.id" v-for="alert in alerts">
                <div class="alert" :class="alert.status ? `alert--${alert.status}` : ''">
                    <div class="alert__content">{{ alert.message }}</div>
                    <div class="alert__actions">
                        <button class="button button--small button--icon button--flat" @click="alertClose(alert.id)">
                            <i class="material-icons">close</i>
                        </button>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import uuid from 'uuid/v4'
import UiLoader from '@/components/UiLoader'
import UiHeader from '@/components/UiHeader'
import UiDrawer from '@/components/UiDrawer'

export default {
    components: {
        UiLoader,
        UiHeader,
        UiDrawer
    },
    data() {
        return {
            alerts: [],
        }
    },
    methods: {
        alertClose(id) {
            this.alerts = this.alerts.filter(alert => alert.id !== id)
        }
    },
    mounted() {
        this.$root.$on('alert', (message, status = 'default', persist = false) => {
            const id = uuid()
            this.alerts = [
                { id, message, status },
                ...this.alerts
            ]

            if (!persist) {
                setTimeout(() => {
                    this.alertClose(id)
                }, 3000)
            }
        })
    },
    beforeDestroy() {
        this.$root.$off('alert')
    }
}
</script>