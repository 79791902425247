<template>
    <div class="form__item form__item--fancy" :class="{ 'form__item--open': inputActive || (value && value.length), 'form__item--active': inputActive, 'form__item--danger': (error && error.length > 0) }">
        <label :for="id">{{ label }}</label>
        <input
            :type="type"
            :id="id"
            :value="value"
            :disabled="disabled"
            @input="$emit('update:value', $event.target.value)"
            @focus="inputActive = true"
            @blur="inputActive = false"
        />
        <div class="form__feedback" v-if="error && error.length > 0">{{ error }}</div>
    </div>
</template>


<script>
export default {
    name: 'frm-input',
    props: {
        label: {
            type: String,
            value: '',
            required: true
        },
        type: {
            type: String,
            value: 'text'
        },
        value: {
            type: String,
            value: ''
        },
        error: {
            type: String,
            value: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            id: '',
            inputActive: false
        }
    },
    mounted() {
        this.id = `input${this._uid}`
    }
}
</script>
