export default {
    namespaced: true,
    getters: {
        axios_options(state, getters, rootState) {
            return {
                headers: {
                    'Authorization': `Bearer ${rootState.auth.token}`
                }
            }
        }
    },
    actions: {
        get({ getters }, { url, options }) {
            url = url || ''
            options = options || {}
            return productsApi.get(url, Object.assign(getters['axios_options'], options))
        },
        post({ getters }, { url, data, options }) {
            url = url || ''
            data = data || {}
            options = options || {}
            return productsApi.post(url, data, Object.assign(getters['axios_options'], options))
        },
        delete({ getters }, { url, options }) {
            url = url || ''
            options = options || {}
            return productsApi.delete(url, Object.assign(getters['axios_options'], options))
        }
    }
}