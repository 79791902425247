import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import validator from './plugins/validator'
import axios from 'axios'

import './scss/app.scss'

Vue.config.productionTip = false

window.axios = axios

window.usersApi = axios.create({
    baseURL: 'https://user-api-portal.zewotherm.com'
})

window.productsApi = axios.create({
    baseURL: 'https://product-api-portal.zewotherm.com'
})

window.vue = new Vue({
    router,
    store,
    validator,
    render: h => h(App)
}).$mount('#app')
